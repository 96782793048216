import React, { useEffect, useState } from "react";
import logo from "./SmakonecupLogo.png";
import bgDekstop from "./background_coming_soon.png";
import bgMobile from "./background_coming_soon_mobile.png";
import sponsors from "./sponsors.PNG"
import './App.css';

function formatNumber(num) {
  return num.toString().padStart(2, "0");
}

function App() {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const targetDate = new Date("October 22, 2024").getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeGap = targetDate - now;

      if (timeGap > 0) {
        const days = Math.floor(timeGap / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeGap % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeGap % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeGap % (1000 * 60)) / 1000);

        setCountdown({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  
  return (
    <div className="App">
      <div>
      <div className='bg-[url("background_coming_soon_mobile.png")] lg:bg-[url("background_coming_soon.png")] bg-cover h-screen w-screen bg-center absolute lg:overflow-auto overflow-y-auto overflow-x-hidden'>

        {/*ComingSoon*/}
        <div className='relative select-none font-clashdisplay text-white opacity-10 text-center rotate-90 lg:rotate-0 translate-y-[350px] lg:translate-y-[200px] text-[12rem] leading-[5.8rem] lg:leading-[2rem] lg:z-10 lg:grid grid-row-2 lg:text-[15rem] min-[376px]:text-[11rem]'>
          <span className='relative flex justify-center pb-4 lg:flex justify-self-start lg:mb-10 lg:pb-10'>coming</span>
          <span className='relative flex justify-center pt-4 lg:flex justify-self-end lg:mt-10 lg:pt-5'>soon</span>
        </div>
        

        {/*RoadTo*/}
        <div className='lg:-mt-[0px] -mt-[80px]'>
        <div className='flex justify-center'>
          <div className='text-white text-center font-normal mb-10 lg:mb-0 font-britney text-[60px] lg:text-[95px] tracking-wide'>SMAKONECUP <br className='lg:hidden'/>2024</div>
        </div>
        
        {/* Register Now */}
        <div className='flex justify-center mt-2 lg:mt-20'>
          <a href='https://scoreboard.smakone.org' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>Scoreboard</a>
          {/* <a href='https://drive.google.com/drive/folders/1qe0OyVc7c16VZmvLeEf5sxXFSY4daOLy' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>REGISTER NOW</a> */}
          {/* <a href='https://drive.google.com/drive/folders/1YYp_lDwUfg9rLVvtSklHjk9D0lZXM3LA?usp=sharing' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>Day 1 Documentation</a> */}
          <a href='https://docs.google.com/document/d/1TxXvv_4JFwnyHugRuhikWjdusGPagN_-uohfIVjk3O0/edit?usp=sharing' target="blank" className='bg-yellow-600 py-[12px] px-[20px] mx-24 font-manrope font-bold justify-center flex text-base text-xl lg:text-2xl
          border-black border-[1px] hover:bg-yellow-100 focus:bg-yellow-100 transition duration-300 ease-in-out z-10'>Daily Documentation</a>
        </div>

        {/* bySOC */}
        <div className='flex justify-center'>
          <div className="font-manrope font-bold text-white mt-16 inline-flex justify-center">
            <span className='text-xl lg:text-2xl mt-2 lg:mt-3'>by</span> 
            <span><img src={logo} alt='' className='w-44 lg:w-52'/></span>
          </div>
        </div>

        <div className="flex flex-col items-center mt-10">

        {/* First Row - Large Logos */}
        <div className="grid grid-cols-5 gap-4 mb-6">
          {/* Replace `src` with the paths to your logo images */}
          {[...Array(5)].map((_, i) => (
            <img key={i} src={require(`./large_logos/${i + 1}.png`)} alt="Large Sponsor Logo" className="w-64 h-64 object-contain" />
          ))}
        </div>

        {/* Second, Third, and Fourth Rows - Medium Logos */}
        {[...Array(1)].map((_, rowIdx) => (
          <div key={rowIdx} className="grid grid-cols-8 gap-4 mb-6">
            {[...Array(8)].map((_, colIdx) => (
              <img key={colIdx} src={require(`./medium_logos/${rowIdx * 8 + colIdx + 1}.png`)} alt="Medium Sponsor Logo" className="w-32 h-32 object-contain" />
            ))}
          </div>
        ))}
        {[...Array(1)].map((_, rowIdx) => (
          <div key={rowIdx} className="grid grid-cols-7 gap-4 mb-6">
            {[...Array(7)].map((_, colIdx) => (
              <img key={colIdx} src={require(`./medium_logos/${8 + rowIdx * 8 + colIdx + 1}.png`)} alt="Medium Sponsor Logo" className="w-32 h-32 object-contain" />
            ))}
          </div>
        ))}

        {/* Rows for Small Logos */}
        {[...Array(1)].map((_, rowIdx) => (
          <div key={rowIdx} className="grid grid-cols-12 gap-4 mb-6">
            {[...Array(12)].map((_, colIdx) => (
              <img key={colIdx} src={require(`./small_logos/${rowIdx * 12 + colIdx + 1}.png`)} alt="Small Sponsor Logo" className="w-12 h-12 object-contain" />
            ))}
          </div>
        ))}
        {[...Array(1)].map((_, rowIdx) => (
          <div key={rowIdx} className="grid grid-cols-12 gap-4 mb-6">
            {[...Array(11)].map((_, colIdx) => (
              <img key={colIdx} src={require(`./small_logos/${12 + rowIdx * 12 + colIdx + 1}.png`)} alt="Small Sponsor Logo" className="w-12 h-12 object-contain" />
            ))}
          </div>
        ))}

      </div>
      </div>
      </div>
    </div>
      
      
    </div>
  );
}

export default App;
